import React, {useEffect, useState} from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import { ScrollRotate } from 'react-scroll-rotate';

import StoneRuneCircle from './../../assets/Png/GraphicStoneCircle.png';
import borderGame from "../../assets/Png/GraphicFrame.png";
import ArrowLeft from "../../assets/Png/GraphicArrowLeft.png";
import ArrowRight from "../../assets/Png/GraphicArrowRight.png";

/*import ImageOne from "../../assets/svg/screenshotOne.svg";
import ImageTwo from "../../assets/svg/screenshotTwo.svg";
import ImageThree from "../../assets/svg/screenshotThree.svg";
import ImageFour from "../../assets/svg/screenshotFour.svg";
import ImageFive from "../../assets/svg/screenshotFive.svg";
import ImageSix from "../../assets/svg/screenshotSix.svg";
import ImageOneBig from "../../assets/svg/big/screenshotOne.svg";
import ImageTwoBig from "../../assets/svg/big/screenshotTwo.svg";
import ImageThreeBig from "../../assets/svg/big/screenshotThree.svg";
import ImageFourBig from "../../assets/svg/big/screenshotFour.svg";
import ImageFiveBig from "../../assets/svg/big/screenshotFive.svg";
import ImageSixBig from "../../assets/svg/big/screenshotSix.svg";*/

/* Old newest - Pre-publish */

import SliderOne from "../../assets/slider/old_newest_live/Enemies2.png";
import SliderTwo from "../../assets/slider/old_newest_live/Fight.png";
import SliderThree from "../../assets/slider/old_newest_live/Harpies.png";
import SliderFour from "../../assets/slider/old_newest_live/Polevik2.png";
import SliderFive from "../../assets/slider/old_newest_live/Shrine.png";
import SliderSix from "../../assets/slider/old_newest_live/Wave.png";

/* Newest - Publish */

/*import SliderOne from "../../assets/slider/newest_live/Attack.jpg";
import SliderTwo from "../../assets/slider/newest_live/Runepad2.jpg";
import SliderThree from "../../assets/slider/newest_live/Upgrade.jpg";
import SliderFour from "../../assets/slider/newest_live/Harpies2.jpg";
import SliderFive from "../../assets/slider/newest_live/Bukavac.jpg";
// import SliderSix from "../../assets/slider/newest_live/Polevik2.jpg";
import SliderSeven from "../../assets/slider/newest_live/Leshy1.jpg";
import SliderEight from "../../assets/slider/newest_live/Waterball+Trap2.jpg";
import SliderNine from "../../assets/slider/newest_live/Enemies.jpg";*/

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function FourthTeaser(){
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const isBiggerMobile = useMediaQuery({ query: '(min-width: 992px)' })

  const { t, i18n } = useTranslation();

  const [sliderOne, setSliderOne] = useState();
  const [sliderTwo, setSliderTwo] = useState();

  const sliderOptions = {
    responsive: [
      {
        breakpoint: 14,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        }
      }
    ]
  }

  return (
    <>
      <div className="teaser-screenshots" id="screenshots">
        {
          isBiggerMobile ? (
            <>
              {/*<div className="corner-stone-rune-screenshots">
                <ScrollRotate>
                  <img src={StoneRuneCircle} alt="Stone Runes"/>
                </ScrollRotate>
              </div>*/}
              {/*<div className="frame-game content-platform">
                <img src={borderGame} alt="Border"/>
              </div>*/}
            </>
          ) : (
            <></>
          )
        }
        <div className="screenshots">
          <ReactBootstrap.Container>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} className="text-center">
                <h1>
                  {t('screenshots')}
                </h1>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              {
                isBiggerMobile ? (
                  <ReactBootstrap.Col xs={12} className="text-center big-slider">
                    <Slider
                      asNavFor={sliderTwo}
                      ref={(slider1) => setSliderOne(slider1)}
                      prevArrow={<img src={ArrowLeft} />}
                      nextArrow={<img src={ArrowRight} />}
                    >
                      <div>
                        <img src={SliderOne} alt="Screenshot one"/>
                      </div>
                      <div>
                        <img src={SliderTwo} alt="Screenshot two"/>
                      </div>
                      <div>
                        <img src={SliderThree} alt="Screenshot three"/>
                      </div>
                      <div>
                        <img src={SliderFour} alt="Screenshot four"/>
                      </div>
                      <div>
                        <img src={SliderFive} alt="Screenshot five"/>
                      </div>
                      {/* Old newest - Pre-publish only */}
                      <div>
                        <img src={SliderSix} alt="Screenshot six"/>
                      </div>
                      {/* Newest - Publish */}
                      {/*<div>
                        <img src={SliderSeven} alt="Screenshot seven"/>
                      </div>
                      <div>
                        <img src={SliderEight} alt="Screenshot eight"/>
                      </div>
                      <div>
                        <img src={SliderNine} alt="Screenshot nine"/>
                      </div>*/}
                    </Slider>
                  </ReactBootstrap.Col>
                ) : (
                  <></>
                )
              }
              <ReactBootstrap.Col xs={12} className="text-center">
                <Slider
                  asNavFor={sliderOne}
                  ref={(slider2) => setSliderTwo(slider2)}
                  slidesToShow={isMobile ? 1 : 5}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  infinite={true}
                  dots={isMobile ? true : false}
                  prevArrow={<></>}
                  nextArrow={<></>}
                >
                  <div>
                    <img src={SliderOne} alt="Screenshot one"/>
                  </div>
                  <div>
                    <img src={SliderTwo} alt="Screenshot two"/>
                  </div>
                  <div>
                    <img src={SliderThree} alt="Screenshot three"/>
                  </div>
                  <div>
                    <img src={SliderFour} alt="Screenshot four"/>
                  </div>
                  <div>
                    <img src={SliderFive} alt="Screenshot five"/>
                  </div>
                  {/* Old newest - Pre-publish only */}
                  <div>
                    <img src={SliderSix} alt="Screenshot six"/>
                  </div>
                  {/* Newest - Publish */}
                  {/*<div>
                    <img src={SliderSeven} alt="Screenshot seven"/>
                  </div>
                  <div>
                    <img src={SliderEight} alt="Screenshot eight"/>
                  </div>
                  <div>
                    <img src={SliderNine} alt="Screenshot nine"/>
                    {//className={isMobile ? "" : "additional-image-update"}}
                  </div>*/}
                </Slider>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </div>
      </div>
    </>
  )
};

export default FourthTeaser;
