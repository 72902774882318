import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Title": "Rune Bender VR",
      "Error-Title": "Page not found",
      "Error-text-one": "This page does not exist or is not more available.",
      "Error-text-two": "Check the URL or click on the link on the bottom.",
      "language": "Language",
      "back-link": "Back",
      "platforms": "Platforms",
      "trailer": "Trailer",
      "screenshots": "Screenshots",
      "description": "Description",
      "imprint": "Imprint",
      "company_info": "Company Info",
      "city": "Cologne",
      "country": "Germany",
      "person_in_charge": "Person in charge",
      "executive_director": "Executive director",
      "situs_and_court_of_registry": "Situs and Court of Registry",
      "trade_register_number": "Trade Register No.",
      "local_court": "Local court",
      "vat_number": "VAT number",
      "terms-of-service": "Terms of service",
      "privacy-policy": "Privacy policy",
      "terms-of-service-title": "Terms of service",
      "privacy-policy-title": "Privacy policy",
      "made-with": "Made with ",
      "in": " in ",
      "by": " by ",
      "liability_for_content": "Liability for Content",
      "text_one_tos": "We make every effort to keep the information on our Web site current, but accept no liability whatsoever for the content provided. Pursuant to §7 par. 1 of TMG (German Tele-Media Act), the law limits our responsibility as a service provider to our own content on these Web pages.",
      "text_two_tos": "According to §§8 to 10 of TMG, we are not obligated to monitor third party information provided or stored on our Web site. However, we shall promptly remove any content upon becoming aware that it violates the law. Our liability in such an instance shall commence at the time we become aware of the respective violation.",
      "liability_for_links": "Liability for Links",
      "text_three_tos": "Our site contains links to third-party Web sites. We have no influence whatsoever on the information on these Web sites and accept no guaranty for its correctness. The content of such third-party sites is the responsibility of the respective owners/providers.",
      "text_four_tos": "At the time third-party Web sites were linked to ours, we found NO GROUNDS WHATSOEVER of any likely contravention of the law. We shall promptly delete a link upon becoming aware that it violates the law.",
      "copyrights": "Copyrights",
      "text_five_tos": "The content and works provided on these Web pages are governed by the copyright laws of Germany. Duplication, processing, distribution, or any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator.",
      "data_protection": "Data Protection",
      "text_six_tos": "Please be aware that there are inherent security risks in transmitting data, such as e-mails, via the Internet, because it is impossible to safeguard completely against unauthorized access by third parties. Nevertheless, we shall safeguard your data, subject to this limitation. In particular, personal information will be transmitted via the Internet only if it does not infringe upon third-party rights, unless the respective party has given its prior consent in view of such security risks. Accordingly, as the Web site provider, we shall not be held liable for any damages incurred as a consequence of such security risks or for any related acts of omission on our part.",
      "text_seven_tos": "We oppose the use of any available contact information by a third party for sending unsolicited advertisements. As the Web site provider, we reserve the express right to take legal action against unsolicited mailing or e-mailing of spam and other similar advertising materials.",
      "source": "Source",
      "home": "Home",
      "description_text_one": 'Plunge into the rich world of Slavic mythology and take on the role of a brave shaman in this immersive VR roguelike game. Your mission is to defend the holy shrine of the god "Veles" from relentless waves of monstrous creatures summoned by the powerful deity "Perun".',
      "description_text_two": "Armed with the power of ancient runes, you'll engage in intense combat against hordes of otherworldly foes. As the waves approach, quickly summon spells by drawing runes in the air. These mystical incantations allow let you unleash devastating magical attacks on your enemies.",
      "description_text_three": 'The stronger the waves, the greater the challenges. They require quick thinking, strategic decisions, and skillful rune drawing. Adjust your tactics and combine spells to create powerful synergies.',
      "description_text_four": 'Prepare for an adrenaline-pumping and immersive VR experience that blends fast-paced combat and strategic rune drawing, all inspired by Slavic mythology. The fate of the sanctuary - and perhaps the entire world - is in your hands.',
      "description_text_five": 'Are you ready to join this epic battle and determine the fate of the world?',
      "description_text_six": 'The shots are the current state of the development and will change frequently until the final release.',
      "requirements": "Requirements",
      "graphic_card": "Graphic card",
      "compatible": "compatible",
      "or": "or",
      "wishlist": "Wishlist",
      "legal_trademark": '"PlayStation" is a registered trademark or trademark of Sony Interactive Entertainment Inc.',
      "processor_data" : "Requires 64-bit processor and operating system",
      "processor_additional": "Do not forget the AMD Graka and CPU",
      "os_text": "or later",
      "equal_better": "or better",
      "newer_text": "or newer",
      "minimum_requirements": "Minimum requirements",
      "recommended": "Recommended",
      "operation_system": "Operation system",
      "processor": "Processor",
      "working_memory": "Ram",
      "graphics": "Graphics",
      "network": "Network",
      "internet_connection": "Broadband Internet connection",
      "vr_support": "VR support",
      "additional_notes": "Additional notes",
      "vr_support-devices": "Supported all-in-one VR headsets",
      "equivalent": "equivalent",
      "betatester": "BETATEST",
      "registration": "registration",
      "registration-normal": "Registration",
      "betatest-text-one": "We are looking for beta testers for the upcoming public beta test on Steam. The beta test is scheduled to start in September and run for about one week. It's all about testing the balancing and finding bugs in the game.",
      "betatest-text-two": "Would you like to play and test the game before everyone else? Then apply now. If you are selected, you will receive all information by e-mail in good time before the start.",
      "register-successful": "Registration was successful",
      "name": "Name",
      "email": "Email",
      "vr_headset": "VR-Headset",
      "steam_nickname": "Steam Nickname",
      "enter-name": "Enter name",
      "enter-email": "Enter email",
      "enter-vr_headset": "Enter VR-Headset",
      "enter-steam_nickname": "Enter Steam Nickname",
      "vr_compatible": "SteamVR / OpenXR compatible VR-Headset",
      "demo": "Demo",
    }
  },
  de: {
    translation: {
      "Title": "Rune Bender VR",
      "Error-Title": "Seite nicht gefunden",
      "Error-text-one": "Diese Seite existiert nicht oder ist nicht mehr verfügbar.",
      "Error-text-two": "Überprüfe die URL oder klicke auf den Link unten.",
      "language": "Sprache",
      "back-link": "Zurück",
      "platforms": "Plattformen",
      "trailer": "Trailer",
      "screenshots": "Screenshots",
      "description": "Beschreibung",
      "imprint": "Impressum",
      "company_info": "Kontakt",
      "city": "Köln",
      "country": "Deutschland",
      "person_in_charge": "Verantwortliche Person",
      "executive_director": "Geschäftsführender Gesellschafter",
      "situs_and_court_of_registry": "Sitz und Registergericht",
      "trade_register_number": "Handelsregisternummer",
      "local_court": "Amtsgericht",
      "vat_number": "Umsatzsteuer-Identifikationsnummer",
      "terms-of-service": "Nutzungsbedingungen",
      "privacy-policy": "Datenschutzrichtlinie",
      "terms-of-service-title": "Nutzungsbedingungen",
      "privacy-policy-title": "Datenschutzrichtlinie",
      "made-with": "Entwickelt mit ",
      "in": " in ",
      "by": " von ",
      "liability_for_content": "Haftung für Inhalte",
      "text_one_tos": "Wir bemühen uns, die Informationen auf unserer Website aktuell zu halten, übernehmen jedoch keinerlei Haftung für die bereitgestellten Inhalte. Gemäß §7 Abs.. 1 TMG sind wir als Diensteanbieter für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.",
      "text_two_tos": "Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.",
      "liability_for_links": "Haftung für Links",
      "text_three_tos": "Unser Angebot enthält Links zu externen Webseiten Dritter. Auf die Inhalte dieser Websites haben wir keinen Einfluss und übernehmen keine Gewähr für die Richtigkeit der dort bereitgestellten Informationen. Für die Inhalte dieser Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.",
      "text_four_tos": "Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.",
      "copyrights": "Urheberrecht",
      "text_five_tos": "Die durch uns erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.",
      "data_protection": "Schutz der Daten",
      "text_six_tos": "Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann, da ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte nicht möglich ist. Dennoch werden wir Ihre Daten mit dieser Einschränkung schützen. Insbesondere werden personenbezogene Daten nur dann über das Internet übertragen, wenn dadurch keine Rechte Dritter verletzt werden, es sei denn, der Betroffene hat in Anbetracht solcher Sicherheitsrisiken zuvor seine Zustimmung erteilt. Dementsprechend haften wir als Betreiber der Website nicht für Schäden, die durch solche Sicherheitsrisiken oder durch Unterlassungen unsererseits entstehen.",
      "text_seven_tos": "Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.",
      "source": "Quelle",
      "home": "Start",
      "description_text_one": 'Tauche ein in die sagenhafte Welt der slawischen Mythologie und übernehme die Rolle eines tapferen Schamanen in diesem immersiven VR-Roguelike-Spiel. Deine Aufgabe ist es, den heiligen Schrein des Gottes "Veles" gegen unerbittliche Wellen monströser Kreaturen zu verteidigen, die von der mächtigen Gottheit "Perun" beschworen wurden.',
      "description_text_two": 'Bewaffnet mit der Macht uralter Runen entfacht ein erbitterter Kampf gegen Horden an Perun\'s Untergebenen. Wenn sich die Wellen nähern, müssen Sie schnell Zaubersprüche beschwören, indem Sie Runen in die Luft zeichnen. Die mystische Magie ermöglich es, verheerende magische Angriffe auf deine Feinde zu entfesseln.',
      "description_text_three": 'Je stärker die Wellen sind, desto größer sind die Herausforderungen, die schnelles Denken, strategische Entscheidungen und richtiges Zeichnen von Runen erfordern. Passe deine Taktik an und kombiniere Zaubersprüche, um mächtige Synergien zu schaffen.',
      "description_text_four": 'Bereite dich auf ein adrenalingeladenes und fesselndes VR-Erlebnis vor, das rasante Kämpfe und strategisches Runenzeichnen kombiniert, inspiriert von der slawischen Mythologie. Das Schicksal des Heiligtums, und vielleicht sogar der ganzen Welt, liegt in deinen Händen.',
      "description_text_five": 'Bist du bereit, dich dieser epischen Schlacht anzuschließen und das Schicksal der Welt zu bestimmen?',
      "description_text_six": 'Die Screenshots spiegeln den aktuellen Entwicklungsstand wider und können sich bis zur endgültigen Veröffentlichung ändern.',
      "requirements": "Mindestanforderungen",
      "graphic_card": "Grafikkarte",
      "compatible": "kompatibles",
      "or": "oder",
      "wishlist": "Wunschliste",
      "legal_trademark": '"PlayStation" ist ein eingetragenes Warenzeichen oder Warenzeichen von Sony Interactive Entertainment Inc.',
      "processor_data" : "Setzt 64-Bit-Prozessor und -Betriebssystem voraus",
      "processor_additional": "AMD Graka und CPU nicht vergessen.",
      "os_text": "oder später",
      "equal_better": "oder besser",
      "newer_text": "oder neuer",
      "minimum_requirements": "Mindestanforderungen",
      "recommended": "Empfohlen",
      "operation_system": "Betriebssystem",
      "processor": "Prozessor",
      "working_memory": "Arbeitsspeicher",
      "graphics": "Grafik",
      "network": "Netzwerk",
      "internet_connection": "Breitband-Internetverbindung",
      "vr_support": "VR-Unterstützung",
      "additional_notes": "Zusätzliche Anmerkungen",
      "vr_support-devices": "Unterstützte All-in-one-VR-Headsets",
      "equivalent": "gleichwertig",
      "betatester": "BETATEST",
      "registration": "Registrierung",
      "registration-normal": "Registrierung",
      "betatest-text-one": "Für den bald anstehenden, öffentlichen Betatest auf Steam suchen wir noch Betatester. Der Betatest soll im September starten und rund eine Woche laufen. Es geht vor allem darum, das Balancing zu testen und Fehler im Spiel zu finden.",
      "betatest-text-two": "Hast du Lust das Spiel vor allen anderen zu sehen und zu testen? Dann bewirb dich jetzt. Wenn du ausgewählt wirst, erhältst du rechtzeitig vor dem Beginn alle Informationen per E-Mail.",
      "register-successful": "Die Registrierung war erfolgreich",
      "name": "Name",
      "email": "E-Mail",
      "vr_headset": "VR-Headset",
      "steam_nickname": "Steam Nickname",
      "enter-name": "Namen eingeben",
      "enter-email": "E-Mail eingeben",
      "enter-vr_headset": "VR-Headset eingeben",
      "enter-steam_nickname": "Steam Nickname eingeben",
      "vr_compatible": "SteamVR / OpenXR kompatibles VR-Headset",
      "demo": "Demo",
    }
  },
  fr: {
    translation: {
      "Title": "Rune Bender VR",
      "Error-Title": "Page non trouvée",
      "Error-text-one": "Cette page n'existe pas ou n'est plus disponible.",
      "Error-text-two": "Vérifie l'URL ou clique sur le lien ci-dessous.",
      "language": "Langue",
      "back-link": "Reto",
      "platforms": "Plates-formes",
      "trailer": "Bande-annonce",
      "screenshots": "Captures d'écran",
      "description": "Description",
      "imprint": "Mentions légales",
      "company_info": "Contact",
      "city": "Cologne",
      "country": "Allemagne",
      "person_in_charge": "Personne responsable",
      "executive_director": "Associé gérant",
      "situs_and_court_of_registry": "Siège et tribunal d'enregistrement",
      "trade_register_number": "Numéro du registre du commerce",
      "local_court": "Tribunal d'instance",
      "vat_number": "Numéro d'identification de la taxe sur le chiffre d'affaires",
      "terms-of-service": "Conditions d'utilisation",
      "privacy-policy": "Politique de confidentialité",
      "terms-of-service-title": "Terms of service",
      "privacy-policy-title": "Privacy policy",
      "made-with": "Développé avec ",
      "in": " dans ",
      "by": " pars ",
      "liability_for_content": "Liability for Content",
      "text_one_tos": "We make every effort to keep the information on our Web site current, but accept no liability whatsoever for the content provided. Pursuant to §7 par. 1 of TMG (German Tele-Media Act), the law limits our responsibility as a service provider to our own content on these Web pages.",
      "text_two_tos": "According to §§8 to 10 of TMG, we are not obligated to monitor third party information provided or stored on our Web site. However, we shall promptly remove any content upon becoming aware that it violates the law. Our liability in such an instance shall commence at the time we become aware of the respective violation.",
      "liability_for_links": "Liability for Links",
      "text_three_tos": "Our site contains links to third-party Web sites. We have no influence whatsoever on the information on these Web sites and accept no guaranty for its correctness. The content of such third-party sites is the responsibility of the respective owners/providers.",
      "text_four_tos": "At the time third-party Web sites were linked to ours, we found NO GROUNDS WHATSOEVER of any likely contravention of the law. We shall promptly delete a link upon becoming aware that it violates the law.",
      "copyrights": "Copyrights",
      "text_five_tos": "The content and works provided on these Web pages are governed by the copyright laws of Germany. Duplication, processing, distribution, or any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator.",
      "data_protection": "Data Protection",
      "text_six_tos": "Please be aware that there are inherent security risks in transmitting data, such as e-mails, via the Internet, because it is impossible to safeguard completely against unauthorized access by third parties. Nevertheless, we shall safeguard your data, subject to this limitation. In particular, personal information will be transmitted via the Internet only if it does not infringe upon third-party rights, unless the respective party has given its prior consent in view of such security risks. Accordingly, as the Web site provider, we shall not be held liable for any damages incurred as a consequence of such security risks or for any related acts of omission on our part.",
      "text_seven_tos": "We oppose the use of any available contact information by a third party for sending unsolicited advertisements. As the Web site provider, we reserve the express right to take legal action against unsolicited mailing or e-mailing of spam and other similar advertising materials.",
      "source": "Source",
      "home": "Lancement",
      "description_text_one": 'Lance-toi dans le monde fascinant de la mythologie slave et incarne un chaman courageux dans ce jeu rogue-like en VR immersif. Ta mission consiste à défendre le sanctuaire sacré du dieu Vélès contre des vagues incessantes de créatures monstrueuses invoquées par la puissante divinité Péroun.',
      "description_text_two": "Armé du pouvoir des runes ancestrales, tu t'engageras dans des combats intenses contre des hordes d'ennemis venues d'un autre monde. Lorsque les vagues de monstres approchent, invoque rapidement des sorts en dessinant des runes dans l'air. Ces incantations mystiques te permettent de lancer des attaques magiques dévastatrices sur tes ennemis.",
      "description_text_three": 'Plus les vagues sont puissantes, plus les défis à relever sont grands. Ils nécessitent une réflexion rapide, des décisions stratégiques et un dessin habile des runes. Ajuste tes tactiques et combine les sorts pour créer de puissantes synergies.',
      "description_text_four": "Prépare-toi à vivre une expérience VR immersive et pleine d'adrénaline, avec des combats rapides et des dessins de runes stratégiques, inspirés de la mythologie slave. Le sort du sanctuaire - et peut-être du monde entier - repose entre tes mains.",
      "description_text_five": 'Es-tu prêt à participer à cette bataille épique et décider du sort du monde?',
      "description_text_six": "Les images représentent l'état actuel du développement et changeront fréquemment jusqu'à la version finale.",
      "requirements": "Configuration",
      "graphic_card": "Carte graphique",
      "compatible": "compatible",
      "or": "ou",
      "wishlist": "Liste de souhaits",
      "legal_trademark": 'PlayStation est une marque enregistrée ou une marque déposée de Sony Interactive Entertainment Inc.',
      "processor_data" : "Nécessite un processeur et un système d'exploitation 64 bits.",
      "processor_additional": "Ne pas oublier la carte graphique AMD et le processeur.",
      "os_text": "ou plus tard",
      "equal_better": "ou mieux",
      "newer_text": "ou plus récent",
      "minimum_requirements": "Configuration",
      "recommended": "Recommandé",
      "operation_system": "Système d'exploitation",
      "processor": "Processeur",
      "working_memory": "Mémoire vive",
      "graphics": "Graphique",
      "network": "Réseau",
      "internet_connection": "Connexion Internet haut débit",
      "vr_support": "Prise en charge VR",
      "additional_notes": "Notes supplémentaires",
      "vr_support-devices": "Casques VR tout-en-un pris en charge",
      "equivalent": "équivalent",
      "betatester": "BÉTA TEST",
      "registration": "Inscription",
      "registration-normal": "Inscription",
      "betatest-text-one": "Nous recherchons des bêta-testeurs pour le test bêta public qui aura lieu prochainement sur Steam. Le test bêta devrait débuter en septembre et durer environ une semaine. Il s'agit avant tout de tester l'équilibrage et de trouver des bugs dans le jeu.",
      "betatest-text-two": "Tu as envie de voir et de tester le jeu avant tout le monde ? Alors pose ta candidature dès maintenant. Si tu es sélectionné, tu recevras toutes les informations par e-mail en temps voulu avant le début.",
      "register-successful": "L'enregistrement a été effectué avec succès",
      "name": "Nom",
      "email": "E-mail",
      "vr_headset": "Casque VR",
      "steam_nickname": "Pseudo Steam",
      "enter-name": "Namen eingeben",
      "enter-email": "Saisir le nom",
      "enter-vr_headset": "Saisir l'e-mail",
      "enter-steam_nickname": "Saisir le casque VR",
      "vr_compatible": "Saisir le pseudo Steam",
      "demo": "Démo",
    }
  },
  it: {
    translation: {
      "Title": "Rune Bender VR",
      "Error-Title": "Pagina non trovata",
      "Error-text-one": "Questa pagina non esiste o non è più disponibile.",
      "Error-text-two": "Controllare l'URL o fare clic sul link sottostante.",
      "language": "Lingua",
      "back-link": "Indietro",
      "platforms": "Piattaforme",
      "trailer": "Trailer",
      "screenshots": "Immagini",
      "description": "Descrizione",
      "imprint": "Impronta",
      "company_info": "Con",
      "city": "Colonia",
      "country": "Germania",
      "person_in_charge": "Responsabile",
      "executive_director": "Socio amministratore",
      "situs_and_court_of_registry": "Sede legale e tribunale",
      "trade_register_number": "Numero di registro commerciale",
      "local_court": "Tribunale locale",
      "vat_number": "Numero di identificazione dell'imposta sulle vendite",
      "terms-of-service": "Condizioni di utilizzo",
      "privacy-policy": "Politica sulla privacy",
      "terms-of-service-title": "Terms of service",
      "privacy-policy-title": "Privacy policy",
      "made-with": "Sviluppato con ",
      "in": " in ",
      "by": " da ",
      "liability_for_content": "Liability for Content",
      "text_one_tos": "We make every effort to keep the information on our Web site current, but accept no liability whatsoever for the content provided. Pursuant to §7 par. 1 of TMG (German Tele-Media Act), the law limits our responsibility as a service provider to our own content on these Web pages.",
      "text_two_tos": "According to §§8 to 10 of TMG, we are not obligated to monitor third party information provided or stored on our Web site. However, we shall promptly remove any content upon becoming aware that it violates the law. Our liability in such an instance shall commence at the time we become aware of the respective violation.",
      "liability_for_links": "Liability for Links",
      "text_three_tos": "Our site contains links to third-party Web sites. We have no influence whatsoever on the information on these Web sites and accept no guaranty for its correctness. The content of such third-party sites is the responsibility of the respective owners/providers.",
      "text_four_tos": "At the time third-party Web sites were linked to ours, we found NO GROUNDS WHATSOEVER of any likely contravention of the law. We shall promptly delete a link upon becoming aware that it violates the law.",
      "copyrights": "Copyrights",
      "text_five_tos": "The content and works provided on these Web pages are governed by the copyright laws of Germany. Duplication, processing, distribution, or any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator.",
      "data_protection": "Data Protection",
      "text_six_tos": "Please be aware that there are inherent security risks in transmitting data, such as e-mails, via the Internet, because it is impossible to safeguard completely against unauthorized access by third parties. Nevertheless, we shall safeguard your data, subject to this limitation. In particular, personal information will be transmitted via the Internet only if it does not infringe upon third-party rights, unless the respective party has given its prior consent in view of such security risks. Accordingly, as the Web site provider, we shall not be held liable for any damages incurred as a consequence of such security risks or for any related acts of omission on our part.",
      "text_seven_tos": "We oppose the use of any available contact information by a third party for sending unsolicited advertisements. As the Web site provider, we reserve the express right to take legal action against unsolicited mailing or e-mailing of spam and other similar advertising materials.",
      "source": "Fonte",
      "home": "Inizio",
      "description_text_one": 'Tuffati nel mondo affascinante della mitologia slava e vesti i panni di un coraggioso sciamano in questo coinvolgente gioco rogue-like in VR. La tua missione è difendere il santuario del dio "Veles" da ondate implacabili di creature mostruose evocate dalla potente divinità "Perun".',
      "description_text_two": 'Con il potere di antiche rune, dovrai affrontare combattimenti intensi contro orde di nemici ultraterreni. Quando le ondate si avvicinano, invoca rapidamente gli incantesimi tracciando delle rune in aria. Questi incantesimi mistici consentono di sferrare attacchi magici e devastanti ai nemici.',
      "description_text_three": "Più forti sono le ondate, maggiori sono le sfide. Serviranno rapidità di pensiero, decisioni strategiche e grandi abilità nell'incidere le rune. Adatta le tue tattiche e combina gli incantesimi per creare potenti sinergie.",
      "description_text_four": "Preparati a un'esperienza VR adrenalinica e coinvolgente, dove dovrai combattere freneticamente e incidere rune con attenzione, il tutto ispirato alla mitologia slava. Il destino del santuario, e forse del mondo intero, è nelle tue mani.",
      "description_text_five": 'Vuoi partecipare a questa epica battaglia per decidere il destino del mondo?',
      "description_text_six": 'Le immagini rappresentano lo stato di sviluppo attuale e subiranno frequenti modifiche fino al completamento del progetto.',
      "requirements": "Requisiti minimi",
      "graphic_card": "Scheda grafica",
      "compatible": "compatibile",
      "or": "o",
      "wishlist": "Lista dei desideri",
      "legal_trademark": 'PlayStation è un marchio registrato o un marchio di Sony Interactive Entertainment Inc.',
      "processor_data" : "Richiede processore e sistema operativo a 64 bit.",
      "processor_additional": "AMD Graka e CPU non dimenticate.",
      "os_text": "o successivo",
      "equal_better": "o migliore",
      "newer_text": "o più recente",
      "minimum_requirements": "Requisiti minimi",
      "recommended": "Consigliato",
      "operation_system": "Sistema operativo",
      "processor": "Processore",
      "working_memory": "Memoria",
      "graphics": "Grafica",
      "network": "Rete",
      "internet_connection": "Connessione Internet a banda larga",
      "vr_support": "Supporto VR",
      "additional_notes": "Note aggiuntive",
      "vr_support-devices": "Cuffie VR all-in-one supportate",
      "equivalent": "equivalente",
      "betatester": "BETATEST",
      "registration": "Registrazione",
      "registration-normal": "Registrazione",
      "betatest-text-one": "Stiamo ancora cercando beta tester per il prossimo beta test pubblico su Steam. Il beta test inizierà a settembre e durerà circa una settimana. L'obiettivo principale è testare il bilanciamento e trovare i bug del gioco.",
      "betatest-text-two": "Volete vedere e testare il gioco prima di tutti gli altri? Allora candidatevi subito. Se verrete selezionati, riceverete tutte le informazioni via e-mail in tempo per l'inizio del gioco.",
      "register-successful": "La registrazione è andata a buon fine",
      "name": "Nome e cognome",
      "email": "Indirizzo e-mail",
      "vr_headset": "Cuffie VR",
      "steam_nickname": "Nickname Steam",
      "enter-name": "Inserisci il nome",
      "enter-email": "Inserisci l'e-mail",
      "enter-vr_headset": "Inserisci la cuffia VR",
      "enter-steam_nickname": "Inserisci il nickname Steam",
      "vr_compatible": "SteamVR/OpenXR compatibile con visore VR",
      "demo": "Demo",
    }
  },
  es: {
    translation: {
      "Title": "Rune Bender VR",
      "Error-Title": "Página no encontrada",
      "Error-text-one": "Esta página no existe o ya no está disponible.",
      "Error-text-two": "Compruebe la URL o haga clic en el siguiente enlace.",
      "language": "Idioma",
      "back-link": "Volver",
      "platforms": "Plataformas",
      "trailer": "Remolque",
      "screenshots": "Capturas de pantalla",
      "description": "Descripción",
      "imprint": "Pie de imprenta",
      "company_info": "Contacto",
      "city": "Colonia",
      "country": "Alemania",
      "person_in_charge": "Persona responsable",
      "executive_director": "Socio gerente",
      "situs_and_court_of_registry": "Domicilio social y tribunal del registro",
      "trade_register_number": "Número de registro mercantil",
      "local_court": "Juzgado local",
      "vat_number": "Número de identificación fiscal",
      "terms-of-service": "Condiciones de uso",
      "privacy-policy": "Política de privacidad",
      "terms-of-service-title": "Terms of service",
      "privacy-policy-title": "Privacy policy",
      "made-with": "Desarrollado con ",
      "in": " en ",
      "by": " de ",
      "liability_for_content": "Liability for Content",
      "text_one_tos": "We make every effort to keep the information on our Web site current, but accept no liability whatsoever for the content provided. Pursuant to §7 par. 1 of TMG (German Tele-Media Act), the law limits our responsibility as a service provider to our own content on these Web pages.",
      "text_two_tos": "According to §§8 to 10 of TMG, we are not obligated to monitor third party information provided or stored on our Web site. However, we shall promptly remove any content upon becoming aware that it violates the law. Our liability in such an instance shall commence at the time we become aware of the respective violation.",
      "liability_for_links": "Liability for Links",
      "text_three_tos": "Our site contains links to third-party Web sites. We have no influence whatsoever on the information on these Web sites and accept no guaranty for its correctness. The content of such third-party sites is the responsibility of the respective owners/providers.",
      "text_four_tos": "At the time third-party Web sites were linked to ours, we found NO GROUNDS WHATSOEVER of any likely contravention of the law. We shall promptly delete a link upon becoming aware that it violates the law.",
      "copyrights": "Copyrights",
      "text_five_tos": "The content and works provided on these Web pages are governed by the copyright laws of Germany. Duplication, processing, distribution, or any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator.",
      "data_protection": "Data Protection",
      "text_six_tos": "Please be aware that there are inherent security risks in transmitting data, such as e-mails, via the Internet, because it is impossible to safeguard completely against unauthorized access by third parties. Nevertheless, we shall safeguard your data, subject to this limitation. In particular, personal information will be transmitted via the Internet only if it does not infringe upon third-party rights, unless the respective party has given its prior consent in view of such security risks. Accordingly, as the Web site provider, we shall not be held liable for any damages incurred as a consequence of such security risks or for any related acts of omission on our part.",
      "text_seven_tos": "We oppose the use of any available contact information by a third party for sending unsolicited advertisements. As the Web site provider, we reserve the express right to take legal action against unsolicited mailing or e-mailing of spam and other similar advertising materials.",
      "source": "Fuente",
      "home": "Inicio",
      "description_text_one": 'Sumérgete en el rico mundo de la mitología eslava y adopta el papel de un valiente chamán en este inmersivo juego roguelike de RV. Tu misión es defender el santuario sagrado del dios Veles de las implacables oleadas de monstruosas criaturas invocadas por la poderosa deidad Perún.',
      "description_text_two": 'Armado con el poder de runas acenstrales, librarás intensos combates contra hordas de enemigos de otro mundo. A medida que se acercan las oleadas, invoca rápidamente hechizos dibujando runas en el aire. Estos conjuros místicos te permitirán desatar devastadores ataques mágicos sobre tus enemigos.',
      "description_text_three": 'Cuanto más fuertes sean las oleadas, mayores serán los desafíos. Requieren rapidez mental, decisiones estratégicas y un gran dominio de las runas. Ajusta tus tácticas y combina hechizos para crear poderosas sinergias.',
      "description_text_four": 'Prepárate para una experiencia de RV envolvente y llena de adrenalina que combina el combate trepidante y el trazado estratégico de runas, todo ello inspirado en la mitología eslava. El destino del santuario, y quizá del mundo entero, está en tus manos.',
      "description_text_five": '¿Estás listo para unirte a esta batalla épica y decidir el destino del mundo?',
      "description_text_six": 'Las imágenes representan el estado actual del desarrollo y cambiarán con frecuencia hasta el lanzamiento final.',
      "requirements": "REQUISITOS",
      "graphic_card": "Tarjeta gráfica",
      "compatible": "compatible",
      "or": "o",
      "wishlist": "Lista de deseos",
      "legal_trademark": 'PlayStation es una marca comercial o una marca comercial registrada de Sony Interactive Entertainment Inc.',
      "processor_data" : "Requiere procesador y sistema operativo de 64 bits",
      "processor_additional": "AMD Graka y CPU no olvidados.",
      "os_text": "o posterior",
      "equal_better": "o superior",
      "newer_text": "o posterior",
      "minimum_requirements": "Requisitos mínimos",
      "recommended": "Recomendado",
      "operation_system": "Sistema operativo",
      "processor": "Procesador",
      "working_memory": "Memoria",
      "graphics": "Gráficos",
      "network": "Red",
      "internet_connection": "Conexión a Internet de banda ancha",
      "vr_support": "Compatible con RV",
      "additional_notes": "Notas adicionales",
      "vr_support-devices": "Cascos de RV todo en uno compatibles",
      "equivalent": "equivalente",
      "betatester": "PRUEBA BETA",
      "registration": "Registro",
      "registration-normal": "Registro",
      "betatest-text-one": "Seguimos buscando beta testers para la próxima prueba beta pública en Steam. La prueba beta comenzará en septiembre y durará aproximadamente una semana. El objetivo principal es probar el equilibrio y encontrar errores en el juego.",
      "betatest-text-two": "¿Quieres ver y probar el juego antes que nadie? Entonces solicítalo ahora. Si eres seleccionado, recibirás toda la información por correo electrónico a tiempo para el comienzo.",
      "register-successful": "La inscripción se ha realizado correctamente",
      "name": "Nombre",
      "email": "Correo electrónico",
      "vr_headset": "Casco de RV",
      "steam_nickname": "Apodo Steam",
      "enter-name": "Introducir nombre",
      "enter-email": "Introducir correo electrónico",
      "enter-vr_headset": "Introduzca VR headset",
      "enter-steam_nickname": "Introducir seudónimo de Steam",
      "vr_compatible": "Cascos de realidad virtual compatibles con SteamVR / OpenXR",
      "demo": "Demo",
    }
  }
};

let languageSetting = "";

if(localStorage.getItem("language") == "" || localStorage.getItem("language") == null){
  languageSetting = "en";
} else if(localStorage.getItem("language") == "en"){
  languageSetting = "en";
} else if(localStorage.getItem("language") == "en"){
  languageSetting = "de";
} else {
  languageSetting = "en"
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: languageSetting,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
